@import '~@which/seatbelt/src/styles/styles';

.fullReviewLink {
  margin-top: $sb-spacing-s;
}

.normalProductCard {
  height: fit-content;

  article {
    border-radius: $sb-spacing-s;
    background-color: white;
  }
}

.compactProductCard {
  height: fit-content;

  article {
    cursor: pointer !important;
    border-radius: $sb-spacing-s;
    background-color: white;
  }

  article:hover {
    box-shadow: 2px 6px 6px -2px sb-color('sb-color-black-20');
  }
}
