@import '~@which/seatbelt/src/styles/styles';

.list {
  @include sb-reset-list;

  display: grid;
  gap: 15px;
  margin-bottom: $sb-spacing-m;
  padding-top: $sb-spacing-s;

  @include sb-respond-to(medium-up) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include sb-respond-to(large) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include sb-respond-to(xlarge) {
    gap: 20px;
  }
}

.newsletterLink {
  height: 100%;
}
