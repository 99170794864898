@import '~@which/seatbelt/src/styles/styles';

.featuredLinks {
  &Wrapper {
    padding: $sb-spacing-xl 0;

    @include sb-respond-to(large) {
      padding: $sb-spacing-3xl 0;
    }
  }

  &GridItem {
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
    padding-top: $sb-spacing-s;

    @include sb-respond-to(medium-up) {
      max-width: revert;
      margin: revert;
    }
  }

  &ArticleCard {
    padding-top: $sb-spacing-s;
    margin-bottom: $sb-spacing-m;

    @include sb-respond-to(large) {
      margin-bottom: 0;
    }
  }
}
