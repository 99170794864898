@import '~@which/seatbelt/src/styles/styles';
.testScoreInline {
  text-transform: uppercase;
}
.score {
  color: $sb-colour-text-negative;
}

.padlockIcon {
  position: relative;
  margin-left: 11px;
  top: -2px;
  path {
    fill: $sb-colour-text-negative;
  }
}
