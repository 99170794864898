@import '~@which/seatbelt/src/styles/styles';

.heading {
  color: sb-color('sb-color-which-red');
}

.button {
  background-color: sb-color('sb-color-which-red');
  border-color: sb-color('sb-color-which-red');
  align-self: flex-start;
  margin: $sb-spacing-m 0;
  &:hover {
    background-color: $sb-colour-background-action-signup-hover;
    border-color: $sb-colour-background-action-signup-hover;
  }
}

.bottomBar {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: $sb-spacing-m;
  background-color: $sb-color-which-red;
}

.heading,
.title {
  margin-bottom: $sb-spacing-s;
}

#clickToRevealOffer {
  overflow: hidden;
  .exclusiveText {
    margin: 0;
  }

  .list {
    padding-left: $sb-spacing-m;
    margin: 0;
    li > :not(:last-child) {
      margin-bottom: $sb-spacing-xs;
    }
    li {
      margin-bottom: $sb-spacing-m;
    }
    margin-bottom: $sb-spacing-s;
  }
}

.closeButton {
  cursor: pointer;
  position: absolute;
  top: $sb-spacing-m;
  right: $sb-spacing-m;
}

.expandedImage {
  width: 100%;
  height: auto;
  margin: $sb-spacing-l 0;
}

.phoneNumber {
  color: $sb-color-which-red;
  text-decoration: none;
  span {
    padding-bottom: $sb-spacing-xs;
  }
}
