@import '~@which/seatbelt/src/styles/styles';

.recommendedArticlesContainer {
  width: auto;
  display: grid;
  column-gap: $sb-spacing-m;
  row-gap: $sb-spacing-m;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @include sb-respond-to(large) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.sectionSpacing {
  padding: $sb-spacing-xl 0;

  @include sb-respond-to(large) {
    padding: $sb-spacing-3xl 0;
  }
}
