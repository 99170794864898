@import '~@which/seatbelt/src/styles/styles';

.sectionSpacing {
  padding: $sb-spacing-xl 0;

  @include sb-respond-to(large) {
    padding: $sb-spacing-3xl 0;
  }
}

.mobileMaxWidthWrapper {
  max-width: 500px;
  margin: 0 auto;
  width: 100%;

  @include sb-respond-to(medium-up) {
    max-width: revert;
    margin: revert;
    width: 100%;
  }

  &.topSpacing {
    @include sb-respond-to(small) {
      margin-top: $sb-spacing-xl;
    }
  }
}

.mobileHomePickUpWhereYouLeftOff {
  max-width: unset;
}

.bottomOnly {
  padding-top: 0;
}

.sectionGrey {
  background-color: $sb-colour-background-subtle;
}

.sectionPink {
  background-color: $sb-colour-background-brand-pink;
}
