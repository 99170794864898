@import '~@which/seatbelt/src/styles/styles';

.list {
  @include sb-reset-list();
  display: flex;
  flex-direction: column;
}

.listItem {
  width: 100%;

  margin-bottom: $sb-spacing-xs;
  min-height: 50px;

  &:not(:first-child) {
    margin-top: $sb-spacing-xs;
  }

  &:not(:last-child) {
    border-bottom: $sb-border-width-s solid $sb-colour-border-muted;
  }

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &TextWrapper {
    flex: 1;
    text-align: left;
  }

  &:hover .listItemText,
  &:focus-visible .listItemText {
    background-size: 100% 1px;
  }

  &Label {
    display: flex;
    align-items: center;
    column-gap: $sb-spacing-m;

    &Text {
      background-image: linear-gradient(currentColor, currentColor);
      background-position: bottom left;
      background-repeat: no-repeat;
      background-size: 0 1px;
      color: $sb-colour-text-default;
      line-height: 140%;
      @media (hover: hover) {
        transition: background-size 300ms 100ms;
      }
    }
  }
}

.icon {
  width: 50px;
  height: calc(50px + $sb-spacing-s);

  &Image {
    background-color: transparent;
    padding: 0;
  }
}
