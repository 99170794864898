@import '~@which/seatbelt/src/styles/styles';

.container {
  padding: $sb-spacing-m 0;
  @include sb-respond-to(medium-up) {
    padding: $sb-spacing-xl 0;
  }

  .innerContainer {
    margin: 0 auto;
    @include sb-respond-to(medium-up) {
      width: 100%;
    }

    .title {
      margin-bottom: $sb-spacing-m;
    }

    .itemsGrid {
      display: flex;
      flex-direction: column;
      gap: $sb-spacing-xl;

      @include sb-respond-to(large) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        > :first-child {
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }
    }
  }
}
