@import '~@which/seatbelt/src/styles/styles';

$benefits-row-padding: 6px;
$benefits-icon-width: 50px;

.benefitLinkRow {
  align-items: center;
  display: flex;
  gap: $sb-spacing-m;
  margin-top: $sb-spacing-xs;
  margin-bottom: $sb-spacing-s;
  padding: $benefits-row-padding;

  &Icon {
    min-width: $benefits-icon-width;
    width: $benefits-icon-width;

    &Image {
      background-color: transparent;
    }
  }
}
