@import '~@which/seatbelt/src/styles/styles';

.header {
  margin-bottom: $sb-spacing-l;
}

.list {
  @include sb-reset-list;
  display: grid;
  gap: 28px;

  @include sb-respond-to(medium-up) {
    grid-template-rows: repeat(5, 1fr);
    grid-auto-flow: column;
  }
}

.listExpanded {
  // Override unwanted margin from ShowMore wrapper when expanded on mobile
  margin-bottom: -34px;

  @include sb-respond-to(medium-up) {
    margin-bottom: 0;
  }
}

.showMoreWrapper {
  @include sb-respond-to(medium-up) {
    margin-bottom: 0;
  }
}

.listItem {
  display: flex;
  align-items: flex-start;
}

.number {
  flex-basis: 28px;
}

.link {
  flex: 1;
  word-break: break-word;

  //TODO CTX-1475 - Use via Seatbelt props when available rather than overriding this
  @include sb-font-medium();
}

.decorativeLink {
  margin-bottom: $sb-spacing-m;

  @include sb-respond-to(medium-up) {
    margin-bottom: 0;
  }
}

.mobileHiddenLink {
  display: none;

  @include sb-respond-to(medium-up) {
    display: flex;
  }
}

.moreButton {
  @include sb-respond-to(medium-up) {
    display: none;
  }
}
