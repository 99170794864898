@import '~@which/seatbelt/src/styles/styles';

$panel-padding: $sb-spacing-m;

.panel {
  position: relative;
  overflow: hidden;
  margin-top: $sb-spacing-s;

  @include sb-respond-to(small) {
    margin-top: $sb-spacing-l;
  }

  padding: $panel-padding;
  border: $sb-border-width-s solid $sb-colour-border-muted;
  background-color: $sb-colour-background-default;

  &HeadingWrapper {
    display: flex;
    flex-direction: column;
  }

  &Title {
    margin-bottom: $sb-spacing-m;
  }

  &Section {
    margin-top: $sb-spacing-m;
  }
}

.callout {
  display: block;
  background-color: $sb-colour-background-brand-blue;
  margin: 20px 0;
  padding: $sb-spacing-s;
}
