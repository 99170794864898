@import '~@which/seatbelt/src/styles/styles';

.list {
  @include sb-reset-list;
  padding-top: $sb-spacing-s;
}

.listItem {
  margin-bottom: $sb-spacing-m;
}
