@import '~@which/seatbelt/src/styles/styles';

$rect-height: 50px;

.footer {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  &Image {
    width: 174px;
    position: relative;
    z-index: 2;
    background-color: transparent;
  }

  &RedBox {
    position: absolute;
    bottom: 0;
    background-color: $sb-colour-background-brand-red;
    height: $rect-height;
    width: 100%;
  }
}
