@import '~@which/seatbelt/src/styles/styles';

$image-container-width: 138px;
$description-container-height: 55px;

.adviceCardSmall {
  display: grid;
  grid-template-columns: $image-container-width 1fr;
  height: 100%;
  gap: $sb-spacing-l;
  .cardImg {
    flex-basis: $image-container-width;
  }
}

.cardImg {
  flex-basis: $image-container-width;
}

.adviceCardDescription {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.adviceCardLarge {
  .title {
    margin-top: $sb-spacing-m;
    margin-bottom: $sb-spacing-xs;
  }
}
