@import '~@which/seatbelt/src/styles/styles';

.popover {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  opacity: 1;
  transition:
    opacity ease-in-out,
    display;
  transition-behavior: allow-discrete;
  transition-duration: 150ms;
  display: block;
  @starting-style {
    opacity: 0;
  }
  &[hidden] {
    display: none;
    opacity: 0;
  }
}
